import React, { useRef, useEffect, useState } from 'react';
import JomiIso from '../assets/img/JomiIso.png'; // Ajusta esta ruta
import AppStore from '../assets/img/AppStore.png'; // Ajusta esta ruta
import GooglePlay from '../assets/img/GooglePlay.png'; // Ajusta esta ruta
import '../assets/css/scratch.css';
import { useParams } from 'react-router-dom';
import { url } from '../api/constants';
import confetti from 'canvas-confetti';

function Scratch() {
    const canvasRef = useRef(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [lastPoint, setLastPoint] = useState({ x: 0, y: 0 });
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [item, setItem] = useState("");
    const { id } = useParams();
    const [code, setCode] = useState("");


    useEffect(() => {
        getData();
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        // Renderiza la imagen
        const image = new Image();
        image.src = JomiIso;  // Ajusta esta ruta si es necesario
        image.onload = () => {
            ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
            ctx.globalCompositeOperation = 'destination-out'; // Permite borrar en lugar de dibujar
            setIsImageLoaded(true); // Marca que la imagen ya está cargada
        };
        // Lanzar confeti al cargar la página
        confetti({
            particleCount: 300,
            spread: 50,
            origin: { y: 0.4 }
        });
    }, []);

    const getData = async (e) => {

        setCode(id.slice(-8));
        const response = await fetch(`${url}/items/active`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(response => response.json()).then(data => {
            console.log(data);
            const Disponibles = data
            const randomPrize = Disponibles[Math.floor(Math.random() * Disponibles.length)];
            setItem(randomPrize);

            const update = fetch(`${url}/update/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ item: randomPrize })
            }).then(response => response.json()).then(data => {
            });

            return data;
        });


    }

    const getCoordinates = (e) => {
        const canvasBounds = canvasRef.current.getBoundingClientRect();
        if (e.touches) {
            // Si es un evento de toque en pantalla táctil
            return {
                x: e.touches[0].clientX - canvasBounds.left,
                y: e.touches[0].clientY - canvasBounds.top
            };
        } else {
            // Si es un evento de ratón
            return {
                x: e.clientX - canvasBounds.left,
                y: e.clientY - canvasBounds.top
            };
        }
    };

    const handleMouseDown = (e) => {
        if (!isImageLoaded) return; // Solo permite raspar si la imagen está cargada
        e.preventDefault();
        const point = getCoordinates(e);
        setIsDrawing(true);
        setLastPoint(point);
    };

    const handleMouseMove = (e) => {
        if (!isDrawing || !isImageLoaded) return; // No permite dibujar si no está cargada la imagen
        e.preventDefault();
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        const currentPoint = getCoordinates(e);

        ctx.lineWidth = 30; // Tamaño del pincel
        ctx.lineCap = 'square'; // Extremos redondeados

        ctx.beginPath();
        ctx.moveTo(lastPoint.x, lastPoint.y);
        ctx.lineTo(currentPoint.x, currentPoint.y);
        ctx.stroke();
        ctx.closePath();

        setLastPoint(currentPoint);
    };

    const handleMouseUp = (e) => {
        e.preventDefault();
        setIsDrawing(false);
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        // Eventos para ratón
        canvas.addEventListener('mousedown', handleMouseDown);
        canvas.addEventListener('mousemove', handleMouseMove);
        canvas.addEventListener('mouseup', handleMouseUp);

        // Eventos para pantallas táctiles
        canvas.addEventListener('touchstart', handleMouseDown);
        canvas.addEventListener('touchmove', handleMouseMove);
        canvas.addEventListener('touchend', handleMouseUp);

        return () => {
            // Limpieza de eventos
            canvas.removeEventListener('mousedown', handleMouseDown);
            canvas.removeEventListener('mousemove', handleMouseMove);
            canvas.removeEventListener('mouseup', handleMouseUp);

            canvas.removeEventListener('touchstart', handleMouseDown);
            canvas.removeEventListener('touchmove', handleMouseMove);
            canvas.removeEventListener('touchend', handleMouseUp);
        };
    }, [isDrawing, lastPoint, isImageLoaded]);

    return (
        <div style={{ backgroundColor: "#FFF" }}>
            <div className="col-lg-12">
                <div className="mx-auto" style={{ height: "450px", backgroundColor: "#8400FF", paddingTop: "30px" }}>
                    <div className="container text-center" style={{ marginTop: "0px", marginBottom: "20px" }}>
                        <label style={{ color: "#FFDF00", fontSize: "30px", fontWeight: "900" }}>¡RASPA Y GANA!</label>
                        {/* <br />
                        <label style={{ color: "#FFDF00", fontSize: "1.7rem", fontWeight: "900" }}>AL INSTANTE</label> */}
                    </div>

                    <div className="container text-center">
                        <div id="scratchArea" className="mx-auto" style={{ position: "relative", width: "300px", height: "300px" }}>
                            {/* Canvas para raspar */}
                            <canvas
                                ref={canvasRef}
                                id="cover"
                                width={300}
                                height={300}
                                style={{
                                    position: "absolute",
                                    top: "0",
                                    left: "0",
                                    zIndex: 2,
                                    border: "none"  // El canvas está por encima
                                }}
                            ></canvas>

                            {/* Texto de Felicidades, posicionado detrás */}
                            {isImageLoaded && (
                                <>
                                    <div id="prize" style={{
                                        position: "absolute",
                                        top: "0",
                                        left: "0",
                                        width: "100%",
                                        height: "100%",
                                        paddingTop: "50px",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        zIndex: 1,  // Está detrás del canvas
                                        color: "white",
                                        fontSize: "18px",
                                        fontWeight: "bold",
                                        opacity: isImageLoaded ? 1 : 0,  // Inicialmente oculto hasta que la imagen se cargue
                                        transition: "opacity 0.3s ease-in-out" // Suaviza la transición
                                    }}>
                                        {item.toUpperCase() === 'GRACIAS' ? (<>
                                            <label>¡FELICIDADES! </label>
                                            <br /> <br /> 
                                            <label style={{ color: "#FFDF00" , fontSize: "20px" }}>¡Ganaste un cupón de $4.99 para usar en tus solicitudes de servicio! </label>
                                             <br /><br />
                                             <p> Descarga y regístrate en JOMIApp </p>
                                             </>
                                
                                            
                                        ) : (
                                            <>
                                            <label>¡FELICIDADES! </label>
                                            <br /> <br /> 
                                            <label style={{ color: "#FFDF00" , fontSize: "20px" }}>¡Ganaste un {item.toUpperCase()}! </label>
                                             <br /><br />
                                             <p> Código Premio: <br /> {code.toString().toUpperCase()} </p>
                                             </>
                                        )}


                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <div className="contenedor-scratch mx-auto" style={{ bottom: "0px", marginLeft: "8px", marginRight: "8px", top: "-25px" }}>
                    <div className="contenedor-scratch mx-auto" style={{ textAlign: "center", fontWeight: "1000", fontSize: "22px", color: "#8400FF" }}>
                        ¡Reclama tu Premio!
                    </div>
                </div>

                <div className="container text-center" style={{ marginTop: "-25px", marginBottom: "20px" }}>
                    <label style={{ color: "#393939", fontSize: "15px", fontWeight: "400" }}>Busca tu premio en el stand de
                        <strong> JOMI</strong>App.</label><br />
                    <label style={{ color: "#393939", fontSize: "15px", fontWeight: "400" }}> No olvides descargar la App para participar
                        por viaje a Margarita para dos personas.</label>
                </div>
                <div className="image-container" style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "20px",
                    marginBottom: "20px"
                }}>
                    <a href='https://testflight.apple.com/join/tsMu9sUd' alt='App Store'> <img src={AppStore} alt="Imagen 1" style={{ maxWidth: "150px", height: "auto", cursor: "Pointer" }} /></a>
                    <a href='https://play.google.com/store/apps/details?id=llc.app.jomi.jomi' alt='Google Play'> <img src={GooglePlay} alt="Imagen 2" style={{ maxWidth: "150px", height: "auto", cursor: "Pointer" }} /></a>
                </div>

                <div className="container text-center">
                    <p className="footer-legal">
                        ¡Asegura tu lugar y registrate en la app!
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Scratch;
