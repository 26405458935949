//import './App.css';
import { useNavigate } from 'react-router-dom';
import React, { useState, useRef } from 'react';
import '../assets/css/form.css';
import Enviar from '../assets/img/Enviar.png';
import LogoCompleto from '../assets/img/LogoMorado.png';
import {url} from '../api/constants';



function Form() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(false);
    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState(false);
    //const namePattern = /^[a-zA-Z]+ [a-zA-Z]+$/;
    const [selectedOptions, setSelectedOptions] = useState(['Proveedor']);
    const [alert, setAlert] = useState({ show: false, message: '', type: '' });
    const [isImageDisabled, setIsImageDisabled] = useState(false); // Estado para controlar si la imagen está deshabilitada

    const formRef = useRef(null);

    const handleImageClick = () => {
        if (formRef.current) {
            handleSubmit();
            
        }
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        if (e.target.value === '') {
            setEmailError(true);
        } else {
            setEmailError(false);
        }
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
        if (e.target.value === '') {
            setNameError(true);
        } else {
            setNameError(false);
        }
    };

    const handlePhoneChange = (e) => {
        const formattedPhone = formatPhoneNumber(e.target.value);
        setPhone(formattedPhone);
        if (!isValidPhoneNumber(formattedPhone)) {
            setPhoneError(true);
        } else {
            setPhoneError(false);
        }
    };

    const formatPhoneNumber = (value) => {
        const phonePattern = /^(\d{0,4})(\d{0,3})(\d{0,2})(\d{0,2})$/;
        const phoneParts = value.replace(/\D/g, '').match(phonePattern);
        if (!phoneParts) return value;
        return `${phoneParts[1]}${phoneParts[2] ? '-' + phoneParts[2] : ''}${phoneParts[3] ? '-' + phoneParts[3] : ''}${phoneParts[4] ? '-' + phoneParts[4] : ''}`;
    };

    const isValidPhoneNumber = (value) => {
        const phonePattern = /^\d{4}-\d{3}-\d{2}-\d{2}$/;
        return phonePattern.test(value);
    };


    const handleOptionChange = (e) => {
        const value = e.target.value;
        setSelectedOptions(prevSelectedOptions =>
            prevSelectedOptions.includes(value)
                ? prevSelectedOptions.filter(option => option !== value)
                : [...prevSelectedOptions, value]
        );
    };

    const handleSubmit = async (e) => {
        if (e) e.preventDefault();

        setIsImageDisabled(true);

        if (name === '') {
            setNameError(true);
            setIsImageDisabled(false);
        } else {
            setNameError(false);
        }

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email)) {
            setEmailError(true);
            setIsImageDisabled(false);
        } else {
            setEmailError(false);
        }

        if (phone !== "" && !isValidPhoneNumber(phone)) {
            setPhoneError(true);
            setIsImageDisabled(false);
        } else {
            setPhoneError(false);
        }

        if ((name === '') === false && !emailPattern.test(email) === false && (phone === '' || (phone !== '' && isValidPhoneNumber(phone)))) {
            
            try {
                const response = await fetch(`${url}/register`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ name, email, phone, intentions: selectedOptions }),
                }).then(response => response.json())
                .then(async data => {
                    //console.log('data', data);
      
                    if (data.status === 200) {
                        const responsemail = await fetch(`${url}/send/mail/promo`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({ name, email }),
                        });
      
                        navigate(`/scratch/${data.data._id}`);
                    } else {
                        showAlert(`El correo ${email} ya está participando!`, 'danger');
                        setIsImageDisabled(false);
                    }
                })
                .catch(error => {
                    console.log('No controlado Error', error);
                    setIsImageDisabled(false);
                    setAlert({ show: true, message: `${error.message}`, type: 'danger' });
                });
            } catch (error) {
                console.log('No controlado Error', error);
                setIsImageDisabled(false);
                setAlert({ show: true, message: `${error.message}`, type: 'danger' });
            }


        } else {
            return;
        }
    };

    const showAlert = (message, type) => {
        setAlert({ show: true, message, type });
        setTimeout(() => {
            setAlert({ show: false, message: '', type: '' });
        }, 3000); // Ocultar la alerta después de 3 segundos
    };

    return (<>
        <div style={{ backgroundColor: "#F3EEFF", padding: "25px", height: "auto" }} >

            <div className="col-lg-12">

                <form ref={formRef} onSubmit={handleSubmit}>
                    <div className="phone-frame mx-auto" style={{ height: "540px", paddingLeft: "20px", paddingRight: "20px" }}>
                        <div className="phone-notch"></div>
                        <img src={LogoCompleto} style={{ width: "100px", marginTop: "60px" }} alt="Slide 1" />
                        {alert.show && (
                            <div className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert" style={{ position: 'fixed', top: '20px', right: '20px', zIndex: 1000 }}>
                                {alert.message}
                            </div>
                        )}

                        <div className="contenedor-label" style={{ marginTop: "10px", width: "100%" }}>
                            <label for="name" className="form-label custom-label">Nombre y Apellido</label>
                            <input type="text" id="name" className="formControl custom-input" />
                            <input
                                type="name"
                                id="name"
                                className={(nameError ? 'form-control custom-input-error' : 'form-control custom-input')}
                                value={name}
                                onChange={handleNameChange}
                                required
                                placeholder='nombre apellido'
                            />
                        </div>

                        <div className="contenedor-label" style={{ marginTop: "50px" }}>
                            <label for="name" className="form-label custom-label">Correo Electrónico</label>
                            <input
                                type="email"
                                id="email"
                                placeholder='mail@mail.com'
                                className={(emailError ? 'form-control custom-input-error' : 'form-control custom-input')}
                                value={email}
                                onChange={handleEmailChange}
                                // onBlur={handleEmailBlur}
                                required
                            />

                        </div>
                        <div className="contenedor-label" style={{ marginTop: "50px" }}>
                            <label for="name" className="form-label custom-label">Teléfono (opcional)</label>
                            {/* <input type="text" id="phone" className="form-control custom-input" /> */}
                            <input
                                type="text"
                                placeholder='0000-000-00-00'
                                id="phone"
                                className={(phoneError ? 'form-control custom-input-error' : 'form-control custom-input')}
                                value={phone}
                                onChange={handlePhoneChange}

                            />
                        </div>

                        <div className="contenedor-label" style={{ marginTop: "50px", textAlign: "center" }}>
                            <label className="form-label label-titulo">¿En qué podremos servirte?</label>
                            <div className="custom-checkbox" style={{ marginTop: "10px" }}>

                                <input
                                    type="checkbox"
                                    id="option1"
                                    name="options"
                                    value="Proveedor"
                                    checked={selectedOptions.includes('Proveedor')}
                                    onChange={handleOptionChange}
                                />
                                <label for="option1">Buscar Proveedores de Servicios</label>
                            </div>
                            <div className="custom-checkbox">

                                <input
                                    type="checkbox"
                                    id="option2"
                                    name="options2"
                                    value="Cliente"
                                    checked={selectedOptions.includes('Cliente')}
                                    onChange={handleOptionChange}
                                />
                                <label for="option2">Ofrecer mis Servicios</label>
                            </div>
                        </div>

                    </div>
                    <div className="back-white mx-auto">
                    </div>
                    <div className="contenedor-boton mx-auto" style={{ bottom: "0px", marginLeft: "8px", marginRight: "8px", top: "-30px" }}>

                        <img src={Enviar} style={{ width: "280px", marginTop: "15px", marginLeft: "20px", cursor: "Pointer" }} onClick={!isImageDisabled ? handleImageClick : null} alt="Slide 1" />

                    </div>
                </form>
                <div className="container text-center" style={{ marginTop: "-20px;", marginBottom: "20px" }}>
                    <label style={{ color: "#000000", fontSize: "0.7rem" }}>Tus datos están a salvo. Los usaremos para ofrecerte
                        un
                        mejor servicio y personalizar tu experiencia.</label>
                </div>
                <div className="container text-center">
                    <p className="footer-legal">
                        <strong>Somos JOMI C.A</strong> &copy; 2024. Todos los derechos reservados.
                    </p>
                </div>
            </div>
        </div >
    </>
    )
}

export default Form;
